import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBUrMjiquyAUgLuRRFr5MjUtSkr2Bl_e48",
    authDomain: "alcit-2f2c2.firebaseapp.com",
    databaseURL: "https://alcit-2f2c2.firebaseio.com",
    projectId: "alcit-2f2c2",
    storageBucket: "alcit-2f2c2.appspot.com",
    messagingSenderId: "1062519043747",
    appId: "1:1062519043747:web:8ebc0844980ea6b100b116"
  };
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

export { firebase, auth, firestore };
